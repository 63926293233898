import React from "react";
import { Row, Container, Breadcrumb, Card } from "react-bootstrap";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { SignUp, isUserSignedIn } from "../../components/accountManagement";

const uri = "/account/signup/";

const SignUpPage = ({ location }) => {
  const userData = useSelector((state) => state.userData);

  return (
    <Layout pageInfo={{ pageName: "signup" }}>
      <Seo title="Sign In" keywords={[`signing`, `login`]} description="Sign Up page" url={uri} />
      <Container fluid>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/">Account</Breadcrumb.Item>
            <Breadcrumb.Item active>Sign Up</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        {isUserSignedIn(userData) ? (
          <>User is signed in {navigate("/")}</>
        ) : (
          <Row className="d-flex flex-wrap">
            <Card className="account-mgmt-card">
              <Card.Body>
                <SignUp
                  onSignUp={() => navigate("/")}
                  signInClick={() => navigate("/account/signin/")}
                  showLogo={false}
                />
              </Card.Body>
            </Card>
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export default SignUpPage;
